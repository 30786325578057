<template lang="pug">
div
  .alert.alert-danger(v-if="error") {{ error }}
  form
    .form-group
      label.font-size-0--75(for="l_url") {{ $t('codeInsertV2.help.askUsForHelp.inputUrl') }}
      input#l_url.form-control(
        :class="{ 'is-invalid': $v.url.$error }"
        placeholder="https://yourwebsite.com/admin"
        type="text"
        v-model="url"
      )
    .form-row
      .form-group.col-sm-6
        label.font-size-0--75(for="l_user") {{ $t('loginName') }}
        input#l_user.form-control(
          :class="{ 'is-invalid': $v.username.$error }"
          type="text"
          v-model="username"
        )
      .form-group.col-sm-6
        label.font-size-0--75(for="l_pass") {{ $t('password') }}
        input#l_pass.form-control(
          :class="{ 'is-invalid': $v.password.$error }"
          type="password"
          v-model="password"
        )
  .d-flex.align-items-center.justify-content-end
    om-button(primary @click="send()" :loading="loading") {{ $t('buttonMsg.send') }}
</template>

<script>
  import SEND_CREDENTIALS from '@/graphql/SendCredentials.gql';
  import { required, url } from 'vuelidate/lib/validators';
  import { track } from '@/services/xray';

  export default {
    data() {
      return {
        url: '',
        username: '',
        password: '',
        error: null,
        loading: false,
      };
    },

    validations: {
      url: {
        required,
        url,
      },
      username: {
        required,
      },
      password: {
        required,
      },
    },

    methods: {
      reset() {
        this.url = '';
        this.username = '';
        this.password = '';
        this.error = null;

        this.$v.$reset();
      },

      send() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.error = this.$t('notifications.validationError');
        } else {
          this.loading = true;
          track('codeInsert-help-askUs-send', null);
          this.$apollo
            .mutate({
              mutation: SEND_CREDENTIALS,
              variables: {
                input: {
                  url: this.url,
                  username: this.username,
                  password: this.password,
                },
              },
            })
            .then(({ data: { sendCredentials } }) => {
              if (sendCredentials.success) {
                this.$modal.hide('send-credentials-modal');
                this.$notify({
                  type: 'success',
                  text: this.$t('notifications.sendSuccess'),
                });
                this.error = null;
              } else {
                throw new Error(sendCredentials.message);
              }
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              this.error = this.$t(`notifications.${e.message}`);
            });
        }
      },
    },
  };
</script>
